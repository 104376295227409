export default {
    methods: {
        GA_Purchase(order_data) {
            let data = {
                event: 'purchase',
                category: '完成訂單', // 類別 字元(必填)
                action: 'click', // 動作 字元(必填)
                label: "完成訂單", // 標籤 字元(選填)
                order_no: order_data.order_id,
                items: [],
                pay_way: this.PaymentConvert(order_data.payment),
                shipping: order_data.shipping_fee,
                value: parseInt(order_data.total_price)
            }

            order_data.products.forEach(product => {
                data.items.push({
                    item_name: product.name,
                    quantity: product.amount,
                    promotion_name: "一般消費",
                    item_category: product.category,
                    item_brand: "銳仕技研",
                    price: parseInt(product.price),
                    value: parseInt(product.price),
                    item_variant: product.options,
                    currency: "TWD",
                })
            });
            this.$gtm.trackEvent(data);
        },
        GA_SelectItem(product) {
            let data = {
                event: 'select_item',
                category: '選擇商品', // 類別 字元(必填)
                action: 'click', // 動作 字元(必填)
                label: "選擇商品", // 標籤 字元(選填)
                items: [{
                    name: product.name,
                    promotion_name: "一般消費",
                    brand: "銳仕技研",
                    price: "",
                    variant: "",
                    currency: "TWD",
                }],
                currency: "TWD",
            }
            this.$gtm.trackEvent(data);
        },
        GA_ClickFreetrial() {
            let data = {
                event: 'start_free_trial',
                category: '免費試用按鈕', // 類別 字元(必填)
                action: 'click', // 動作 字元(必填)
                label: "點擊免費試用", // 標籤 字元(選填)
            }
            this.$gtm.trackEvent(data);
        },
        GA_ChooseProgram(val) {
            let data = {
                event: 'choose_program',
                category: '選擇方案', // 類別 字元(必填)
                action: 'click', // 動作 字元(必填)
                label: val, // 標籤 字元(選填)
                program: val
            }
            this.$gtm.trackEvent(data);
        },
        GA_PersonalDataForm() {
            let data = {
                event: 'finish_personal_form',
                category: '填寫個人資料', // 類別 字元(必填)
                action: 'submit', // 動作 字元(必填)
                label: '填寫個人資料', // 標籤 字元(選填)
            }
            this.$gtm.trackEvent(data);
        },
        GA_CompanyDataForm() {
            let data = {
                event: 'finish_company_form',
                category: '填寫公司資料', // 類別 字元(必填)
                action: 'submit', // 動作 字元(必填)
                label: '填寫公司資料', // 標籤 字元(選填)
            }
            this.$gtm.trackEvent(data);
        },
        GA_ImageForm() {
            let data = {
                event: 'finish_image_form',
                category: '上傳圖片', // 類別 字元(必填)
                action: 'submit', // 動作 字元(必填)
                label: '上傳圖片', // 標籤 字元(選填)
            }
            this.$gtm.trackEvent(data);
        }
    }
}