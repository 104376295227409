var default_meta = {
  title: '新世代的人脈網',
  content:
    '資訊時代不可或缺的個人形象網站如何打通網路世界中自己的任督二脈。MYii，新世代的人脈網是你不可或缺的個人形象網站。與你更貼近的距離，由MYii開始',
  image: window.location + '/img/share.jpg',
};

export function GetMetaData(title, content, image) {
  title = title == '' ? default_meta.title : title;
  return {
    title: title + ' | Myii | 個人形象網站',
    meta: [
      {
        property: 'og:description',
        content: content == '' ? default_meta.content : content,
        vmid: 'og:description',
      },
      {
        property: 'twitter:description',
        content: content == '' ? default_meta.content : content,
        vmid: 'twitter:description',
      },
      {
        property: 'description',
        content: content == '' ? default_meta.content : content,
        vmid: 'description',
      },
      {
        property: 'og:image',
        content: image == '' ? default_meta.image : image,
        vmid: 'og:image',
      },
      {
        property: 'apple-mobile-web-app-title',
        content: title + ' | Myii | 個人形象網站',
        vmid: 'apple-mobile-web-app-title',
      },
      {
        property: 'application-name',
        content: title + ' | Myii | 個人形象網站',
        vmid: 'application-name',
      },
      {
        property: 'og:site_name',
        content: title + ' | Myii | 個人形象網站',
        vmid: 'og:site_name',
      },
      {
        property: 'og:type',
        content: 'website',
        vmid: 'og:type',
      },
      {
        property: 'og:locale',
        content: 'zh_tw',
        vmid: 'og:locale',
      },
    ],
  };
}
