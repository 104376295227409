<template>
  <section id="HomeCover">
    <div class="row">
      <div class="content_box">
        <header>
          <h2>
            <b>征服世界的工具<br />人脈將是你最好的選擇</b>
          </h2>
          <ol>
            <li>你賺的錢12.5%來自知識</li>
            <li>87.5%來自關係</li>
            <li>MYii將是新時代的利器</li>
          </ol>
        </header>
      </div>
      <div class="img_box">
        <img src="/img/phone/phone_frame.png" />
        <video muted autoplay loop playsinline src="/1015-2.mp4"></video>
      </div>
    </div>
    <div class="shape_bottom">
      <img src="/img/home-bottom-shape.png" />
    </div>
  </section>
</template>

<script>
import home_cover_gsap from "@/common/GSAP/home_cover.js"
export default {
  name: "HomeCover",
  mixins: [home_cover_gsap],
  data() {
    return {}
  },
  methods: {},
  mounted() { }
}
</script>