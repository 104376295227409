<template>
  <div v-if="dialog" id="VideoModal">
    <div class="modal_box">
      <button @click="CloseModal" class="close_btn">
        <span class="material-icons-outlined"> close </span>
      </button>
      <div id="player"></div>
    </div>
  </div>
</template>

<script>
var YTReady = false
export default {
  name: "VideoModal",
  data() {
    return {
      player: null,
      done: false
    }
  },
  methods: {
    CloseModal() {
      this.$store.commit("SetVideo", false)
    },
    stopVideo() {
      this.player.stopVideo();
    },
    SetVideo(id) {
      let vm = this
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(tag, firstScript);
      window.onYouTubeIframeAPIReady = function () {
        YTReady = true
        // eslint-disable-next-line no-undef
        vm.player = new YT.Player('player', {
          videoId: id, // YouTube Video ID
          width: 277,               // Player width (in px)
          height: 600,              // Player height (in px)
          playerVars: {
            //autoplay: 1,        // Auto-play the video on load
            controls: 1,        // Show pause/play buttons in player
            showinfo: 1,        // Hide the video title
            modestbranding: 1,  // Hide the Youtube Logo
            //loop: 1,            // Run the video in a loop
            fs: 0,              // Hide the full screen button
            cc_load_policy: 0, // Hide closed captions
            iv_load_policy: 3,  // Hide the Video Annotations
            autohide: 1,         // Hide video controls when playing
            playsinline: 1, //forbid fullscreen on ios
          },
          events: {
            onReady: (e) => {
              e.target.mute();
              e.target.playVideo()
            },
            // onStateChange: (e) => { vm.onPlayerStateChange(e) }
          }
        });
      }
      // eslint-disable-next-line no-undef
      if (YTReady) {
        // eslint-disable-next-line no-undef
        vm.player = new YT.Player('player', {
          videoId: '5Tv5ytyakTA', // YouTube Video ID
          width: 277,               // Player width (in px)
          height: 600,              // Player height (in px)
          playerVars: {
            autoplay: 1,        // Auto-play the video on load
            controls: 0,        // Show pause/play buttons in player
            showinfo: 1,        // Hide the video title
            modestbranding: 1,  // Hide the Youtube Logo
            loop: 1,            // Run the video in a loop
            fs: 0,              // Hide the full screen button
            cc_load_policy: 0, // Hide closed captions
            iv_load_policy: 3,  // Hide the Video Annotations
            autohide: 1,         // Hide video controls when playing
            playsinline: 1, //forbid fullscreen on ios
          },
          events: {
            onReady: (e) => {
              e.target.mute();
              e.target.playVideo()
            },
            onStateChange: (e) => { vm.onPlayerStateChange(e) }
          }
        });
      }
    }
  },
  watch: {
    dialog() {
      this.dialog ? this.SetVideo("vqztR_zAJKg") : "";
    }
  },
  computed: {
    dialog() {
      return this.$store.state.video_modal
    }
  }
}
</script>