<template>
  <main id="Home">
    <Cover />
    <Features />
    <Video />
    <OverView />
    <Process />
    <Price />
    <Footer />
  </main>
</template>

<script>
import SmoothScrollResister from '@/common/GSAP/SmoothScrollResister.js';
import Cover from '@/components/Home/Cover';
import OverView from '@/components/Home/OverView';
import Video from '@/components/Home/Video';
import Features from '@/components/Home/NewFeatures';
import Process from '@/components/Home/Process';
import Price from '@/components/Home/Price';
import Footer from '@/components/MainFooter';
import { GetMetaData } from '@/common/meta.js';
export default {
  name: 'Home',
  mixins: [SmoothScrollResister],
  components: {
    Cover,
    OverView,
    Video,
    Features,
    Process,
    Price,
    Footer,
  },
  data() {
    return {
      meta_data: null,
    };
  },
  methods: {},
  mounted() {
    this.RegisterGsap('Home');
    this.$nextTick(() => {
      if (this.$route.hash) {
        const el = document.querySelector(this.$route.hash);
        let el_pos = el.offsetTop;
        this.ScrollToEl(el_pos);
        this.meta_data = GetMetaData('', '', '');
        window.prerenderReady = true;
      }
    });
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
