import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    header_status: false,
    menu_status: false,
    video_modal: false,
    loading_status: false,
    preview_modal: false,
    dialog: {
      status: false,
      content: "",
      title: ""
    },
    demo_url: "https://www.yongxin-design.com/demo_card/"
  },
  mutations: {
    SetHeader(state, action) {
      state.header_status = action
    },
    SetVideo(state, action) {
      state.video_modal = action
    },
    SetPreview(state, action) {
      state.preview_modal = action
    },
    SetMenu(state, action) {
      state.menu_status = action
    },
    SetLoading(state, action) {
      state.loading_status = action
    },
    SetDemoUrl(state, url) {
      state.demo_url = url
    },
    SetDialog(state, {
      action,
      content,
      title
    }) {
      state.dialog.status = action
      state.dialog.content = content
      state.dialog.title = title
    }
  },
  actions: {},
  modules: {}
})