<template>
  <section id="HomePrice">
    <div class="row">
      <!-- <div class="preview_card">
        <div class="content">
          <p>簡易設定、專人服務、三天到手</p>
          <h4>1個月免費使用</h4>
          <p>
            *綁定信用卡時會需要試刷一筆10元訂單，刷卡成功後系統將自動作廢該筆10元定單，您不會被收取測試費用。
          </p>
          <p>
            *如期滿之後須解約必須在結帳日前聯繫客服，若無其他原因將會綁訂續刷。
          </p>
          <p>*其他調整及客製化不適用兩個月免費試用的優惠。</p>
          <router-link @click.native="GA_ClickFreetrial()" to="/get_start?p=3"
            >免費試用</router-link
          >
        </div>
      </div> -->
      <div class="card_list">
        <div
          class="card"
          v-for="item in programs"
          :key="`price_card_${item.id}`"
        >
          <div class="header">
            <h4>{{ item.name }}</h4>
            <p>{{ item.sub_title }}</p>
          </div>
          <div class="content">
            <h4 v-if="!isNaN(item.price)">
              <b><span>$</span>{{ item.price_text }}</b> /{{
                item.type | TypeConvert
              }}
            </h4>
            <h4 v-else>
              <b>{{ item.price }}</b>
            </h4>
            <p class="description" v-html="item.description"></p>

            <router-link
              @click.native="GA_ChooseProgram(item.name)"
              :to="`/get_start?p=${item.id}`"
              >{{ item.button_text }}</router-link
            >
            <p v-for="option in item.info.split(',')" :key="option.id">
              {{ option }}
            </p>
          </div>
        </div>
        <div class="card">
          <div class="header">
            <h4>客製化方案</h4>
            <p>訂製專屬於您的名片</p>
          </div>
          <div class="content">
            <h4>
              <span>請向銷售人員洽詢定價資訊</span>
            </h4>
            <router-link to="/contact">聯絡銷售人員</router-link>
            <p>無限量的傳送次數</p>
            <p>客製化頁數</p>
            <p>Logo、封面圖設計</p>
            <p>互動式表單</p>
            <p>專業攝影修圖</p>
            <p>加購選項自由配</p>
          </div>
        </div>
      </div>
      <div class="comment_box">
        <p>專案可加購品項</p>
        <p>
          加碼頁數、LOGO設計、互動表單、獨立網址、縮寫網址、封面圖、拍照修圖
        </p>
        <p>更多加購詳細與客製化方案請與<a>我們聯絡</a></p>
      </div>
    </div>
  </section>
</template>

<script>
import axios_plugin from "@/common/axios_plugin";
export default {
  name: "HomePrice",
  mixins: [axios_plugin],
  data() {
    return {
      programs: [],
    };
  },
  methods: {
    async GetPrograms() {
      let result = await this.SendGetData(
        `${process.env.VUE_APP_BASE_API}/Programs/Read.php`
      );
      if (result != "error") {
        this.programs = result.data;
      }
    },
  },
  filters: {
    TypeConvert(val) {
      return val == "m" ? "月" : val == "y" ? "年" : "";
    },
  },
  mounted() {},
  async created() {
    await this.GetPrograms();
  },
};
</script>