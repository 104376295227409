/* eslint-disable no-unused-vars */
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import Scrollbar, {
    ScrollbarPlugin
} from 'smooth-scrollbar';

class ModalPlugin extends ScrollbarPlugin {
    static pluginName = 'modal';

    static defaultOptions = {
        open: false,
    };

    transformDelta(delta) {
        return this.options.open ? {
            x: 0,
            y: 0
        } : delta;
    }
}

Scrollbar.use(ModalPlugin, /* OverscrollPlugin */ );
var bodyScrollBar = null;

export default {
    methods: {
        InitGsapMarker() {
            if (document.querySelector('.gsap-marker-scroller-start')) {
                const markers = gsap.utils.toArray('.gsap-marker-end, .gsap-marker-start'); // <<---- changed

                bodyScrollBar.addListener(({
                    offset
                }) => {
                    gsap.set(markers, {
                        marginTop: -offset.y
                    })
                });
            }
        },
        RegisterGsap(page_id) {
            gsap.registerPlugin(ScrollTrigger);
            const scroller = document.querySelector('#' + page_id);
            bodyScrollBar = Scrollbar.init(scroller, {
                damping: 0.1,
                delegateTo: document,
                alwaysShowTracks: true
            });
            Scrollbar.detachStyle()

            ScrollTrigger.scrollerProxy("#" + page_id, {
                scrollTop(value) {
                    if (arguments.length) {
                        bodyScrollBar.scrollTop = value;
                    }
                    return bodyScrollBar.scrollTop;
                },
                fixedMarkers: true
            });

            bodyScrollBar.addListener(ScrollTrigger.update);
            bodyScrollBar.addListener((status) => {
                let offset = status.offset
                if (offset.y >= 200) {
                    this.$store.commit("SetHeader", true)
                } else {
                    this.$store.commit("SetHeader", false)
                }
            })
            ScrollTrigger.defaults({
                scroller: scroller
            });
        },
        ModalToggle(action) {
            bodyScrollBar.updatePluginOptions('modal', {
                open: action
            });
        },
        ScrollToEl(el_pos) {
            bodyScrollBar.scrollTo(0, el_pos, 1000)
        }
    },
    beforeDestroy() {
        // bodyScrollBar.destroy()
    }
}