<template>
  <header id="MainHeader" :class="{ active: header_statue }">
    <div class="row">
      <router-link to="/" class="logo_link">
        <img src="/img/logo.svg" />
      </router-link>

      <a
        :class="{ active: menu }"
        @click="ToggleMenuAction"
        class="menu_btn link_mark"
      >
        <i></i>
        <i></i>
        <i></i>
      </a>
      <div class="side_button_box">
        <ol>
          <li>
            <a @click="MenuToggle('HomeOverView'), ToggleMenuAction()"
              ><span>OVERVIEW</span>特色介紹</a
            >
          </li>
          <li>
            <a @click="MenuToggle('NewFeatures'), ToggleMenuAction()"
              ><span>FEATURES</span>功能簡介</a
            >
          </li>
          <li>
            <a @click="MenuToggle('HomeProcess'), ToggleMenuAction()"
              ><span>APPLICATION PROCESS</span>申請流程</a
            >
          </li>
          <li>
            <a @click="MenuToggle('HomePrice'), ToggleMenuAction()"
              ><span>PRICE</span>定價</a
            >
          </li>
          <li>
            <router-link to="/case_preview" @click.native="ToggleMenuAction()"
              ><span>Project</span>案例分享</router-link
            >
          </li>
          <li>
            <router-link to="/contact" @click.native="ToggleMenuAction()"
              ><span>Contact</span>聯絡我們</router-link
            >
          </li>
          <li>
            <router-link
              @click.native="ToggleMenuAction()"
              to="/get_start"
              class="hight_light"
              ><span>GET START</span>開始使用</router-link
            >
          </li>
        </ol>
      </div>
    </div>
  </header>
</template>

<script>
import main_header_gsap from "@/common/GSAP/main_header.js"
export default {
  name: "MainHeader",
  mixins: [main_header_gsap],
  methods: {
    ToggleMenuAction() {
      this.toggleMenu(!this.menu)
    }
  },
  computed: {
    header_statue() {
      if (this.$route.name != "Home") {
        return true
      }
      else {
        return this.$store.state.header_status
      }
    },
    menu() {
      return this.$store.state.menu_status
    }
  }
}
</script>