<template>
  <div id="MainDialog" v-if="dialog">
    <div class="dialog_box">
      <div class="header">
        <p v-html="title"></p>
        <button @click="Close">
          <span class="material-icons-outlined"> close </span>
        </button>
      </div>
      <div class="body">
        <p v-html="content"></p>
      </div>
      <div class="footer">
        <button @click="Close" class="close_btn">關閉</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "MainDialog",
  data() {
    return {}
  },
  methods: {
    Close() {
      this.$store.commit("SetDialog",
        {
          action: false,
          title: "",
          content: ""
        })
    }
  },
  computed: {
    dialog() {
      return this.$store.state.dialog.status
    },
    title() {
      return this.$store.state.dialog.title
    },
    content() {
      return this.$store.state.dialog.content
    }
  }
}
</script>