<template>
  <section id="HomeOverView">
    <div class="row">
      <header>
        <p><b>OVERVIEW</b></p>
        <h2><b>MYii的人際關係運用</b></h2>
      </header>
      <div class="card_list">
        <div class="card" v-for="(item, item_index) in cards" :key="item.id">
          <div
            class="img_box"
            :class="{
              active:
                (active_card && item_index % 2 == 0) ||
                (!active_card && item_index % 2 != 0),
            }"
          >
            <span class="material-icons-outlined">
              {{ item.icon }}
            </span>
          </div>
          <div class="content_box">
            <h4>{{ item.title }}</h4>
            <p v-html="item.content"></p>
          </div>
          <span class="word_count">{{ item_index + 1 }}</span>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import home_overview_gsap from "@/common/GSAP/home_overview"
export default {
  name: "HomeOverView",
  mixins: [home_overview_gsap],
  data() {
    return {
      cards: [
        {
          title: "初次見面",
          content: "掌握數位時代精髓<br>讓名片交換更便利",
          icon: "memory"
        },
        {
          title: "社群發文",
          content: "結交志同道合夥伴時<br>更快速聯絡彼此",
          icon: "reply_all"
        },
        {
          title: "推薦神器",
          content: "當被介紹推薦時<br>訊息不遺漏",
          icon: "send_to_mobile"
        },
        {
          title: "無窮機會",
          content: "跳脫印刷限制<br>一機在手名片無窮",
          icon: "all_inclusive"
        }
      ],
      active_card: true,
      card_animation_timer: null
    }
  },
  methods: {
  },
  mounted() {
    this.card_animation_timer = setInterval(() => {
      this.active_card = !this.active_card
    }, 1000);
  }
}
</script>