import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/get_start',
    name: 'GetStart',
    component: () =>
      import(/* webpackChunkName: "get_start" */ '../views/GetStart.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () =>
      import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  },
  {
    path: '/order_create',
    name: 'OrderCreate',
    component: () =>
      import(/* webpackChunkName: "order_create" */ '../views/OrderFinish.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy" */ '../views/PrivacyPolicy.vue'
      ),
  },
  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    component: () =>
      import(/* webpackChunkName: "terms-of-use" */ '../views/TermsOfUse.vue'),
  },
  {
    path: '/purchase-agreement',
    name: 'PurchaseAgreement',
    component: () =>
      import(
        /* webpackChunkName: "purchase-agreement" */ '../views/PurchaseAgreement.vue'
      ),
  },
  {
    path: '/detail_form',
    name: 'DetailForm',
    component: () =>
      import(/* webpackChunkName: "detail_form" */ '../views/DetailForm.vue'),
  },
  {
    path: '/easy_form',
    name: 'EasyForm',
    component: () =>
      import(/* webpackChunkName: "easy_form" */ '../views/EasyForm.vue'),
  },
  {
    path: '/case_preview',
    name: 'CasePreview',
    component: () =>
      import(/* webpackChunkName: "case_preview" */ '../views/CasePreview.vue'),
  },
  {
    path: '/index.php',
    name: 'BotHome',
    component: Home,
  },
  {
    path: '/error_page',
    name: 'NotFound',
    component: () =>
      import(/* webpackChunkName: "not_found" */ '../views/NotFound.vue'),
  },
  {
    path: '*',
    redirect: '/error_page',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

export default router;
