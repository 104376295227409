<template>
  <section id="NewFeatures">
    <div class="row">
      <header>
        <h3>功能簡介</h3>
        <p>
          名片不再只是名片，MYii為用戶建構全新的溝通型態，成為用戶生活中不可或缺的社交工具。
        </p>
      </header>
      <div class="card_list">
        <div class="card" v-for="item in features" :key="item.id">
          <div class="img_box">
            <img alt="Myii 個人形象網站" :src="item.image" />
          </div>
          <div class="content_box">
            <div class="content">
              <h4>{{ item.title }}</h4>
              <p v-html="item.content"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "NewFeatures",
  data() {
    return {
      features: [
        {
          title: "封面大圖",
          content:
            "映入眼簾是代表自己及公司給予大眾的第一眼，代表的形象照、精神標語、企業形象等，可拉近與顧客的距離，也更加活潑、親切，擺脫過去的嚴肅及制式，創造自己的人脈。",
          image: "/img/features/1.png",
        },
        {
          title: "標誌LOGO",
          content:
            "公司LOGO/個人簽名檔可以搭配整體視覺，在過去無法盡情表現的簽名以及企業的視覺互相搭配著，在體驗實潛移默化的深入人心。",
          image: "/img/features/2.png",
        },
        {
          title: "名片標題與敘述",
          content:
            "擔心過去頭銜以及服務項目太多的老闆們有福了，現在所有項目有了專屬的空間，再也不用擔心放不完的資訊，現在只要想著如何填滿自己的人生敘述。",
          image: "/img/features/3.png",
        },
        {
          title: "功能按鈕",
          content:
            "系統化的建構，簡單明瞭讓資訊一點就連結到對應的應用程式中，解決傳統名片的資訊要重新輸入這複雜的過程，對任何人簡單上手，節省寶貴時間。",
          image: "/img/features/4.png",
        },
        {
          title: "可擴充多頁面",
          content:
            "結合個人、公司、品牌不同的資訊於一張個人形象網站，讓個人與公司皆可以相互並進，替自己行銷時候就是在替公司建立企業品牌形象，可謂CP值最高的名片。",
          image: "/img/features/5.png",
        },
      ],
    };
  },
};
</script>