<template>
  <footer id="MainFooter">
    <div class="footer">
      <div class="row">
        <div class="card">
          <img class="logo" src="/img/logo.svg" />
          <div class="social_box">
            <a href="https://line.me/R/ti/p/%40334xpyda" target="_blank">
              <img src="/img/line_icon.svg" />
            </a>
            <a href="https://www.facebook.com/MYiiCard" target="_blank">
              <img src="/img/fb_icon.svg" />
            </a>
            <a href="https://www.instagram.com/myiicard/" target="_blank">
              <img src="/img/ig_icon.svg" />
            </a>
          </div>
        </div>
        <div class="card">
          <p>上班時間:周一至周五</p>
          <p>上午:09:00-12:00</p>
          <p>下午:13:00-18:00</p>
        </div>
        <div class="card">
          <p>客服專線：<a href="tel:04-2471-9799">04-2471-9799</a></p>
          <p>
            客服信箱：<a href="mailto:hello@myiicard.com">hello@myiicard.com</a>
          </p>
          <p>
            官方LINE@：<a
              href="https://line.me/R/ti/p/%40334xpyda"
              target="_blank"
              >@334xpyda</a
            >
          </p>
        </div>
      </div>
    </div>
    <div class="copyright_box">
      <div class="row">
        <p>Copyright © 2021 Myii Inc. 著作權所有，並保留一切權利。</p>
        <div class="link_group">
          <router-link to="/privacy-policy">隱私權政策和使用條款</router-link>
          <router-link to="/terms-of-use">使用條款</router-link>
          <router-link to="/purchase-agreement">購物須知</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter"
}
</script>