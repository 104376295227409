import gsap from "gsap"
import SmoothScrollResister from "@/common/GSAP/SmoothScrollResister.js"
import ScrollTrigger from "gsap/ScrollTrigger"


export default {
    mixins: [SmoothScrollResister],
    data() {
        return {
            main_menu_gsap: null
        }
    },
    methods: {
        toggleMenu(action) {
            let vm = this
            vm.main_menu_gsap == null ? "" : vm.main_menu_gsap.kill()
            if (action) {
                ScrollTrigger.matchMedia({
                    "(max-width:768px)"() {
                        vm.main_menu_gsap = gsap.timeline()
                        vm.main_menu_gsap.set("#MainHeader .side_button_box", {
                            width: "100vw"
                        })
                        vm.main_menu_gsap.fromTo("#MainHeader .side_button_box", {
                            opacity: 0
                        }, {
                            opacity: 1,
                            ease: "power2.out",
                            duration: .3
                        })
                        vm.$store.commit('SetMenu', true)
                    },
                })
            } else {

                ScrollTrigger.matchMedia({
                    "(max-width:768px)"() {
                        vm.main_menu_gsap = gsap.timeline()

                        vm.main_menu_gsap.fromTo("#MainHeader .side_button_box", {
                            opacity: 1
                        }, {
                            opacity: 0,
                            ease: "power2.out",
                            duration: .3
                        }, 0)
                        vm.main_menu_gsap.set("#MainHeader .side_button_box", {
                            width: "0vw"
                        }, 1)
                        vm.$store.commit('SetMenu', false)
                    },
                })
            }
        },
        MenuToggle(id) {
            if (this.$route.name != "Home") {
                this.$router.push("/#" + id)
            } else {
                const el = document.querySelector("#" + id)
                let el_pos = el.offsetTop
                this.ScrollToEl(el_pos)
            }
        }
    }
}