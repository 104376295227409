<template>
  <div id="PreviewDialog" v-if="$store.state.preview_modal">
    <div class="modal_box">
      <button @click="CloseModal" class="close_btn">
        <span class="material-icons-outlined"> close </span>
      </button>
      <div class="demo_box">
        <iframe
          height="600px"
          marginwidth="0"
          marginheight="0"
          scrolling="No"
          frameborder="0"
          :src="$store.state.demo_url"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreviewDialog",
  data() {
    return {}
  },
  methods: {
    CloseModal() {
      this.$store.commit("SetPreview", false)
    }
  }
}
</script>