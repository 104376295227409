<template>
  <div id="MainLoading" v-if="loading">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="535.9px"
      height="325.5px"
      viewBox="0 0 535.9 325.5"
      style="overflow: visible; enable-background: new 0 0 535.9 325.5"
      xml:space="preserve"
    >
      <defs></defs>

      <!-- <path
            class="st0"
            d="M515.1,274.7c-27.3,27.3-63.7,42.4-102.3,42.4c-23.3,0-40.5-5.7-53.8-10.3c-20-6.9-35.4-15.6-45.9-22.6
	c-87.4-0.5-174.9-0.9-262.3-1.4c-23.3,0-42.3-18.9-42.3-42.3V94c0-23.3,18.9-42.3,42.3-42.3C99.8,51.9,148.9,52,198,52
	c36.1,0,72.2-0.1,108.3-0.3v-0.2c0-23.3,18.9-42.3,42.3-42.3c41.1-0.2,82.2-0.5,123.3-0.7c23.3,0,42.3,18.9,42.3,42.3
	c0.3,54.7,0.7,109.4,1,164.1C531.6,231.5,531.6,258.2,515.1,274.7z"
          /> -->

      <g>
        <path
          class="st1"
          d="M523.5,211.5l-1-160.8c0-28-22.8-50.7-50.8-50.7L348.6,0.7c-25.2,0-46.1,18.4-50.1,42.5
		c-33.3,0.1-67.1,0.2-100.5,0.2c-6.4,0-12.8,0-19.3,0c-42.5,0-85.5-0.1-128-0.3C22.8,43.1,0,65.9,0,93.9v146.6
		c0,28,22.8,50.7,50.7,50.7l259.8,1.4c14.3,9.2,29.7,16.6,45.7,22.1c13.3,4.6,31.5,10.8,56.6,10.8c40.9,0,79.3-15.9,108.2-44.9
		C540,261.7,540.8,231.4,523.5,211.5z M515.1,274.7c-27.3,27.3-63.7,42.4-102.3,42.4c-23.3,0-40.5-5.7-53.8-10.3
		c-20-6.9-35.4-15.6-45.9-22.6c-87.4-0.5-174.9-0.9-262.3-1.4c-23.3,0-42.3-18.9-42.3-42.3V94c0-23.3,18.9-42.3,42.3-42.3
		C99.8,51.9,148.9,52,198,52c36.1,0,72.2-0.1,108.3-0.3v-0.2c0-23.3,18.9-42.3,42.3-42.3c41.1-0.2,82.2-0.5,123.3-0.7
		c23.3,0,42.3,18.9,42.3,42.3c0.3,54.7,0.7,109.4,1,164.1C531.6,231.5,531.6,258.2,515.1,274.7z"
        />
        <path
          class="st1"
          d="M130.4,86.8L120,145.5l-10.4-58.7h-66v159h48.1v-50.4l9.4,50.4h37.8l9.4-50.4v50.4h48.1v-159H130.4z M193.6,242.9h-42.5v-78.1l-14.5,78.1h-33.1l-14.5-78.1v78.1H46.4V89.6h60.8l12.8,72l12.8-72h60.8V242.9z"
        />
        <path
          class="st1"
          d="M278.2,86.8l-9.9,35.1l-9.9-35.1h-55.2l39.8,96.3v62.7h50.6v-62.6l40.5-96.3H278.2z M290.8,182.6v60.4h-44.9
		v-60.4l-38.4-92.9h48.9l12,42.7l12-42.7h49.5L290.8,182.6z"
        />
        <path
          class="st1"
          d="M341.4,86.8v125.5H392V86.8H341.4z M389.2,209.5h-44.9V89.6h44.9V209.5z"
        />
        <path
          class="st1"
          d="M426.5,86.8v125.5h50.6V86.8H426.5z M474.2,209.5h-44.9V89.6h44.9V209.5z"
        />
        <path
          class="st1"
          d="M341.4,44.3v34.1H392V44.3H341.4z M389.2,75.6h-44.9V47.2h44.9V75.6z"
        />
        <path
          class="st1"
          d="M426.5,43.6v35.5h50.6V43.6H426.5z M474.2,76.3h-44.9V46.5h44.9V76.3z"
        />
        <path
          class="st1"
          d="M466.7,217.5l-4.4,4.4c-13.5,13.5-31.4,20.9-50.4,20.9c-19,0-36.9-7.4-50.4-20.9l-4.4-4.4l-26.3,26.3l4.4,4.4
		c20.5,20.5,47.8,31.8,76.8,31.8s56.2-11.3,76.8-31.8l4.4-4.4L466.7,217.5z M486.6,246.2c-20,20-46.5,31-74.8,31
		c-28.2,0-54.8-11-74.8-31l-2.4-2.4l22.4-22.4l2.4,2.4c14,14,32.6,21.7,52.4,21.7s38.4-7.7,52.4-21.7l2.4-2.4l22.4,22.4L486.6,246.2
		z"
        />
        <g>
          <path
            class="st1"
            d="M104.4,93l15.4,86.8h0.4L135.6,93h54.6v146.6h-35.7V130.3H154l-20.3,109.2h-27.5L85.9,130.3h-0.4v109.2H49.8
			V93H104.4z"
          />
        </g>
        <path
          class="st1"
          d="M212.5,93h41.3l14.4,51.1h0.4L282.9,93h41.9l-37.4,88.9v57.7h-38.2v-57.7L212.5,93z"
        />
        <rect x="347.6" y="50.5" class="st1" width="38.2" height="21.7" />
        <rect x="432.7" y="49.8" class="st1" width="38.2" height="23.1" />
        <g>
          <path
            class="st1"
            d="M411.9,273.9c-27.3,0-53-10.7-72.4-30l17.6-17.6c14.7,14.7,34.1,22.7,54.8,22.7c20.7,0,40.1-8.1,54.8-22.7
			l17.6,17.6C464.9,263.2,439.2,273.9,411.9,273.9z"
          />
        </g>
        <path
          class="st1"
          d="M347.6,93v113.1h38.2V93H347.6z M366.7,177c-2.9,0-5.3-2.4-5.3-5.3c0-2.9,2.4-5.3,5.3-5.3
		c2.9,0,5.3,2.4,5.3,5.3C372,174.6,369.6,177,366.7,177z"
        />
        <path
          class="st1"
          d="M432.7,93v113.1h38.2V93H432.7z M451.8,177c-2.9,0-5.3-2.4-5.3-5.3c0-2.9,2.4-5.3,5.3-5.3
		c2.9,0,5.3,2.4,5.3,5.3C457.1,174.6,454.7,177,451.8,177z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "MainLoading",
  data() {
    return {}
  },
  computed: {
    loading() {
      return this.$store.state.loading_status
    }
  }
}
</script>