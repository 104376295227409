import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueGtm from 'vue-gtm'
import gtm_methods from "./common/gtm_methods"

Vue.use(VueAxios, axios)
Vue.mixin(gtm_methods)
Vue.config.productionTip = false
Vue.use(VueMeta, {
  refreshOnceOnNavigation: false
})
Vue.use(VueGtm, {
  id: "GTM-PK2D227",
  enabled: true,
  debug: true,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')