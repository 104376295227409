<template>
  <section id="HomeVideo">
    <div @click="PlayVideo" class="content_box">
      <button class="play_btn">
        <span class="material-icons-outlined"> play_arrow </span>
        <i></i>
        <i></i>
        <i></i>
      </button>
      <p>WATCH VIDEO</p>
    </div>
    <div class="img_box">
      <img alt="Myii 個人形象網站" src="/img/video-bg.jpg" />
    </div>
  </section>
</template>

<script>
import SmoothScrollResister from "@/common/GSAP/SmoothScrollResister.js";
export default {
  name: "HomeVideo",
  mixins: [SmoothScrollResister],
  data() {
    return {};
  },
  methods: {
    PlayVideo() {
      this.$store.commit("SetVideo", true);
    },
  },
  mounted() {},
};
</script>