<template>
  <section id="HomeProcess">
    <div class="row">
      <header>
        <p><b>APPLICATION PROCESS</b></p>
        <h2><b>擁有MYii五步驟</b></h2>
      </header>
      <div class="card_list">
        <div
          class="card"
          v-for="(item, item_index) in process_array"
          :key="item.id"
        >
          <span>{{ item_index + 1 }}</span>
          <div class="content">
            <h4 v-html="item.title"></h4>
            <p v-html="item.content"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import home_process_gsap from "@/common/GSAP/home_process"
export default {
  name: "HomeProcess",
  mixins: [home_process_gsap],
  data() {
    return {
      process_array: [
        {
          title: "詢問官方客服人員，選定版型、項目",
          content: "客製化內容，顏色版型任意挑選，更有其他服務項目可以加購，可使品牌完整性更高。"
        },
        {
          title: "取得訂單編號至購物車結帳",
          content: "經由客服人員將需求整理完成後確認最終價格，到線上結帳系統付款，訂單即可確認製作。"
        },
        {
          title: "填寫詳細資料",
          content: "填寫詳細個人資料後提交給客服人員，進行調整以及資料核對。"
        },
        {
          title: "MYii生成確認無誤",
          content: "作業完成後生成網址連結確認MYii無誤。"
        },
        {
          title: "在主頁面建立捷徑",
          content: "將頁面網址設定於主頁面/桌面，方便於快速使用，也紀錄在個人手機中。"
        }
      ]
    }
  },
  methods: {},
  mounted() { }
}
</script>