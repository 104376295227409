import gsap from "gsap"
import SmoothScrollResister from "@/common/GSAP/SmoothScrollResister.js"
// import ScrollTrigger from "gsap/ScrollTrigger"


export default {
    mixins: [SmoothScrollResister],
    data() {
        return {
            home_process_gsap: null,
        }
    },
    methods: {},
    mounted() {
        this.RegisterGsap("Home")
        let vm = this

        vm.home_process_gsap = gsap.timeline({
            scrollTrigger: {
                trigger: "#HomeProcess",
                start: "top center",
                end: "bottom center",
                toggleActions: "restart play play reverse"
            }
        });

        vm.home_process_gsap.fromTo("#HomeProcess header h2 b", {
            opacity: 0,
            y: "100%"
        }, {
            opacity: 1,
            y: "0",
            duration: .4,
        }, 0)
        vm.home_process_gsap.fromTo("#HomeProcess header p b", {
            opacity: 0,
            y: "100%"
        }, {
            opacity: 1,
            y: "0",
            duration: .4,
            delay: .2
        }, 0)

        let cards = document.querySelectorAll("#HomeProcess .card_list .card")

        cards.forEach((item, item_index) => {


            vm.home_process_gsap.fromTo(item, {
                opacity: 0,
            }, {
                opacity: 1,
                duration: .4,
                delay: .1 * item_index
            }, 0)
        })


        this.InitGsapMarker()
    },
    beforeDestroy() {
        this.home_process_gsap != null ? this.home_process_gsap.kill() : ""
    }
}