<template>
  <div id="app">
    <MainDialog />
    <MainLoading />
    <MainHeader />
    <VideoModal />
    <PreviewModal />
    <router-view />
  </div>
</template>

<style lang="scss" src="@/assets/css/app.scss"></style>

<script>
import MainHeader from "@/components/MainHeader"
import VideoModal from "@/components/VideoModal"
import PreviewModal from "@/components/PreviewDialog"
import MainLoading from "@/components/MainLoading"
import MainDialog from "@/components/MainDialog"
export default {
  name: "App",
  components: {
    MainHeader,
    VideoModal,
    MainLoading,
    MainDialog,
    PreviewModal
  },
  data() {
    return {}
  },
  methods: {},
  mounted() { }
}
</script>