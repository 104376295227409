import gsap from "gsap"
import SmoothScrollResister from "@/common/GSAP/SmoothScrollResister.js"
// import ScrollTrigger from "gsap/ScrollTrigger"


export default {
    mixins: [SmoothScrollResister],
    data() {
        return {
            home_cover_gsap: null,
            home_video_text_gsap: null
        }
    },
    methods: {},
    mounted() {
        this.RegisterGsap("Home")
        let vm = this

        vm.home_cover_gsap = gsap.timeline();

        vm.home_cover_gsap.fromTo("#HomeCover header h2 b", {
            opacity: 0,
            y: "100%"
        }, {
            opacity: 1,
            y: "0",
            duration: .5,
            delay: 1
        }, 0)

        vm.home_cover_gsap.fromTo("#HomeCover .img_box", {
            x: "30%",
            opacity: 0
        }, {
            x: "0",
            opacity: 1,
            duration: .5,
            delay: 1
        }, 0)

        let list_items = document.querySelectorAll("#HomeCover header ol li")

        list_items.forEach((item, item_index) => {
            vm.home_cover_gsap.fromTo(item, {
                opacity: 0,
                // x: "-50%"
            }, {
                opacity: 1,
                // x: "0",
                duration: .3,
                delay: (item_index * 0.1)
            })
        }, 1)


        this.InitGsapMarker()
    },
    beforeDestroy() {
        this.home_cover_gsap != null ? this.home_cover_gsap.kill() : ""
        this.home_video_text_gsap != null ? this.home_video_text_gsap.kill() : ""
    }
}